import { graphql, Link, useStaticQuery } from "gatsby"
import * as React from "react"
import Header from "../components/header"
import "@fontsource/montserrat/100-italic.css"
import "@fontsource/montserrat/200-italic.css"
import "@fontsource/montserrat/300-italic.css"
import "@fontsource/montserrat/400-italic.css"

import Seo from "../components/seo"
import { AvisoStyle } from "../styles"

const AvisoPrivacidadPage = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      allFile {
        edges {
          node {
            publicURL
            name
            ext
          }
        }
      }
    }
  `)

  const logo =
    data.allFile.edges.filter(file => file.node.name === "leaseforu-logo")[0] ||
    null
  console.log(logo)
  return (
    <AvisoStyle>
      <div>
        <div className="header">
          <Link to="/">
            <img src={logo.node.publicURL} className="leaseforu-logo" />
          </Link>
        </div>
        <div className="body-aviso">
          <h1 className="titulo-aviso">
            AVISO DE PRIVACIDAD INTEGRAL - CLIENTES
          </h1>
          <p>
            LEASE FOR YOU S.A. de C.V., (Lease for U o LFU) con domicilio en
            Carretera México-Toluca 5420 Piso 7 Int. 701 Col. El Yaqui CP.
            05320, Cuajimalpa de Morelos, Ciudad de México, le informa que LFU
            es Responsable del tratamiento de los datos personales que solicita
            y que toda su información es tratada de forma estrictamente bajo
            medidas de seguridad físicas, técnicas y administrativas.
          </p>
          <h2> I. Datos que se tratan de usted como Titular: </h2>
          <ul>
            <li>Identificación y contacto</li>
            <li>Datos Fiscales*</li>
            <li>Datos Financieros*</li>
            <li>
              Datos Identificación, contacto y datos fiscales de terceros (Aval,
              Fiador u Obligado Solidario)*
            </li>
          </ul>
          <p>
            Los datos marcados con un * son considerados sensibles, por lo que
            al solicitar un servicio pondremos a su disposición el presente
            aviso de privacidad y le solicitaremos sea firmado lo que
            representará el consentimiento expreso respecto al tratamiento de
            sus datos personales. El tratamiento de sus datos personales realiza
            tanto dentro como fuera de los Estados Unidos Mexicanos y entiende
            que podrán serán tratados directamente por (LFU), sus filiales,
            subsidiarias, así como terceros proveedores de servicios con quienes
            tiene una relación jurídica, así como en su caso autoridades
            competentes
          </p>
          <h2>II. Las finalidades primarias o necesarias:</h2>
          <p style={{ paddingLeft: "5%" }}>
            - Ofrecerle y otorgarle el servicio de arrendamiento de equipo
            médico.
          </p>
          <p style={{ paddingLeft: "5%" }}>
            - Verificar la información proporcionada por usted
          </p>
          <p style={{ paddingLeft: "5%" }}>
            - Mantener actualizados nuestros registros para poder responder a
            sus consultas.
          </p>
          <h2>
            III. Las finalidades secundarias, no necesarias o bien que no dan
            origen a la relación jurídica con LFU:
          </h2>
          <p style={{ paddingLeft: "5%" }}>- Actividades promocionales</p>
          <p style={{ paddingLeft: "5%" }}>
            - Análisis estadísticos y de mercado
          </p>
          <p style={{ paddingLeft: "5%" }}>
            - Ofrecerle productos, servicios e información de las Marcas, así
            como de nuestros socios de negocios.
          </p>
          <h2>IV. Transferencias de datos personales</h2>
          <p>
            Las únicas transferencias de datos que se podrán realizar serán para
            cubrir los siguientes supuestos:
          </p>
          <ol type="I">
            <li>que exista una disposición legal que obligue a hacerlo,</li>
            <li>
              que exista una solicitud por parte de una autoridad competente,
            </li>
            <li>
              que la transferencia sea necesaria o legalmente exigible para
              salvaguardar un interés público o para la procuración o
              administración de justicia, o
            </li>
            <li>
              que la transferencia sea precisa para el reconocimiento, ejercicio
              o defensa de un derecho en un proceso judicial y
            </li>
            <li>
              cuando la transferencia sea precisa para el mantenimiento o
              cumplimiento de una relación jurídica entre LFU (Responsable del
              tratamiento de los datos personales) y usted (Titular de los datos
              personales).
            </li>
            <li>
              cuando se cuente con autorización expresa de usted (Titular de los
              datos personales) para compartir su información o aquella de la
              que es responsable.
            </li>
          </ol>
          <p>
            Para prevenir el acceso no autorizado a sus datos personales y con
            el fin de asegurar que la información sea utilizada para los fines
            establecidos en este aviso de privacidad, hemos establecido
            procedimientos físicos, electrónicos y administrativos, que limitan
            el uso o divulgación de sus datos.
          </p>
          <p>
            En concordancia con los supuestos anteriores, informamos que las
            transferencias podrán ser a:
          </p>
          <ul>
            <li>
              Autoridades que requieren la información en términos de la
              legislación aplicable.
            </li>
            <li>
              Entidades dentro de nuestro mismo grupo corporativo, las cuales
              operan bajo los mismos procesos y políticas internas de LFU.
            </li>
            <li>
              Autoridades que nos la soliciten como parte de un proceso judicial
              o administrativo donde sea necesario revelar información o datos
              personales.
            </li>
          </ul>
          <p>
            En cualquier caso, ya sea que la transferencia sea nacional o
            internacional, entregaremos o pondremos a disposición del receptor,
            el presente Aviso de Privacidad y de que éste asuma, por lo menos,
            las mismas obligaciones a las que LFU se encuentra sujeto. Esto,
            mediante cláusulas contractuales u otros instrumentos jurídicos
            celebrados entre LFU y los receptores de los datos o aceptaciones
            electrónicas/automatizadas de los mismos.
          </p>
          <p>
            Le informamos que las transferencias listadas en este apartado están
            todas permitidas en términos del artículo 37 de la Ley, por lo cual
            no es necesario que este Aviso de Privacidad contenga una cláusula
            de aceptación de transferencias de datos personales.
          </p>
          <p>
            Le hacemos también notar que LFU podría requerir compartir (remitir)
            información o datos personales con proveedores (encargados) que nos
            prestan servicios y en caso de que requieran el tratamiento de
            datos, estos utilizarán la información o datos personales solamente
            en nuestro nombre, conforme a nuestra instrucción y en términos del
            contrato por escrito donde se establezca el alcance y contenido de
            nuestra relación con el proveedor (encargado) correspondiente.
          </p>
          <h2>
            V. Atención a los derechos de Acceso, Rectificación, Cancelación,
            Oposición de datos personales (Solicitud ARCO), Revocación del
            consentimiento y Limitación del tratamiento.
          </h2>
          <p>
            La Ley Federal de Protección de Datos Personales en Posesión de los
            Particulares contempla derechos que usted como Titular o dueños de
            los datos personales, puede ejercer en relación con los datos
            personales y pueden ejercerse en cualquier momento. A estos derechos
            se les nombra “Derechos ARCO” y se enuncian a continuación:
          </p>
          <ol type="I">
            <li>
              {" "}
              Acceder a aquellos datos personales que se encuentran en posesión
              de LFU, así como a conocer los detalles del tratamiento de los
              mismos,
            </li>

            <li>
              {" "}
              Rectificarlos en caso de estar desactualizados, ser inexactos o
              estar incompletos,
            </li>

            <li>Cancelarlos, y</li>

            <li>
              {" "}
              Oponerse al tratamiento de los mismos para fines específicos.
            </li>
          </ol>
          <h3>a. Procedimiento de solicitud de atención a Derechos ARCO</h3>
          <p>
            El presente procedimiento podrá ser ejecutado para el ejercicio de
            Derechos ARCO, Revocación de consentimiento o Limitación del
            tratamiento realizado por LFU de algún Titular o dueño de sus datos,
            así como para cualquier otra solicitud que requiera realizar con
            respecto al tratamiento de datos personales o información a la que
            LFU pueda tratar.
          </p>
          <p>
            El solicitante deberá enviar un correo electrónico a
            info@leaseforu.com, acompañado de la siguiente información y
            documentación:
          </p>
          <ol type="I">
            <li>Nombre completo del Titular.</li>

            <li>
              Correo electrónico del Titular para comunicarle la respuesta a su
              solicitud.
            </li>
            <li>
              {" "}
              En su caso, razón social o denominación del Cliente al que
              representa.
            </li>
            <li>
              En su caso, indicar tipo de relación que tiene con el Cliente y
              correo electrónico institucional.
            </li>
          </ol>
          <p>Adjuntar la siguiente documentación:</p>
          <ol type="I">
            <li>
              Identificación oficial vigente que acredite la identidad del
              Titular y/o solicitante.
            </li>
          </ol>
          <p>En caso de requerir la representación legal deberá adjuntarse: </p>
          <ol type="I">
            <li>
              La identificación oficial vigente del Titular y del representante
              legal.
            </li>
            <li>
              Instrumento público (poder frente a fedatario público) o carta
              poder firmada ante 2 testigos, o declaración en comparecencia
              personal del titular, lo anterior para acreditar la representación
              del titular.
            </li>
          </ol>
          <p>
            Respecto al derecho ARCO a ejercer, Revocación de consentimiento o
            Limitación del tratamiento realizado por LFU, favor de indicar:
          </p>
          <ol type="I">
            <li>
              La descripción clara y precisa de los datos personales o
              información respecto de los que se busca ejercer alguno de los
              derechos antes mencionados.
            </li>
            <li>
              Cualquier otro elemento o documento que facilite la localización
              de los datos personales o la información tratada.
            </li>
          </ol>
          <p>
            Respecto a cualquier otra solicitud de información que desee
            realizar, favor de indicar:
          </p>
          <ol type="I">
            <li>La descripción clara y precisa de su solicitud.</li>
            <li>
              Justificación o motivo por el que requeriré se atienda la
              solicitud.
            </li>
          </ol>
          <p>
            Una vez que la solicitud para ejercer Derechos ARCO, se encuentre en
            disposición de la LFU, el Responsable al interior de LFU emitirá la
            contestación en un plazo no mayor a 20 (veinte) días hábiles, a
            partir de su fecha de recepción.
          </p>
          <p>
            En caso de requerir información adicional de su solicitud, se le
            hará saber dentro del plazo de 5 (cinco) días hábiles, a partir de
            la fecha de recepción de la solicitud. Contará con 10 (diez) días
            hábiles para atender el requerimiento de información, pasado el
            plazo se deberá volver a ingresar su solicitud.
          </p>
          <p>
            Una vez recibida la respuesta sobre la confirmación de la atención
            de la solicitud, LFU resolverá la solicitud en un plazo de 15
            (quince) días hábiles.
          </p>
          <p>
            Los plazos antes referidos podrían ser ampliados una sola vez por un
            periodo igual, siempre y cuando así lo justifiquen las
            circunstancias del caso. En caso de no responder a nuestra
            resolución con alguna confirmación, entenderemos que de buena fe se
            encuentra conforme con la resolución.
          </p>
          <p>
            Hacemos notar que, como encargado de los datos personales, LFU podrá
            negarse al ejercicio del Derecho ARCO solicitado, en los supuestos
            que lo permita la Ley y su Reglamento, en cuyo caso informaremos las
            razones de dicha negativa al solicitante. La negativa podrá ser
            parcial, en cuyo caso efectuaremos el acceso, rectificación,
            cancelación u oposición en la parte procedente.
          </p>
          <h3>
            b. Revocación del consentimiento al tratamiento de sus datos
            personales
          </h3>
          <p>
            Podrá revocar su consentimiento para el tratamiento de los datos
            personales o información, siguiendo el mismo procedimiento que para
            el ejercicio de sus Derechos ARCO (ver apartado el inciso "a)" de
            este aviso), en el entendido que una vez que su solicitud de
            revocación se encuentre a en disposición de LFU, se emitirá una
            contestación en un plazo máximo de 5 (cinco) días hábiles.
          </p>
          <h3>
            c. Opciones para limitar el uso o divulgación de sus datos
            personales
          </h3>
          <p>
            Se puede limitar el uso o divulgación de los mismos siguiendo el
            mismo procedimiento que para el ejercicio los Derechos ARCO (ver el
            inciso a) de este aviso), en el entendido que una vez que la
            solicitud se encuentre en disposición de LFU, se emitirá la
            contestación en un plazo no mayor a 5 (cinco) días hábiles.
          </p>
          <p>
            A fin de promover la limitación del uso y divulgación de datos,
            hacemos notar que existen otros mecanismos como el Registro Público
            para Evitar Publicidad <span>(REPEP)</span> que administra la
            Procuraduría Federal del Consumidor (https://repep.profeco.gob.mx/)
            y el Registro Público de Usuarios que no deseen información
            publicitaria de Productos y Servicios Financieros{" "}
            <span>(REUS)</span> de CONDUSEF
            (https://webapps.condusef.gob.mx/reus/app/registro.jsp), en los que
            podrá inscribirse de forma gratuita.
          </p>
          <h2>VI. Dudas, quejas y comentarios</h2>
          <p>
            En cualquier momento podrá contactarnos, ya que estamos a su entera
            disposición para atender cualquier inquietud, queja o comentario al
            respecto del tratamiento de su información y cualquier dato personal
            que tratemos en su nombre.
          </p>
          <h2>VII. Costo de trámites</h2>
          <p>
            Todos los trámites antes expuestos son gratuitos y previo acuerdo
            entre el Titular o dueño de los datos personales y/o Cliente y LFU
            se podrá cobrar el medio por el cual se requiera la respuesta o
            envió de la información (en su caso).
          </p>
          <h2>
            VIII. Uso de cookies, web beacons y otras tecnologías similares
          </h2>
          <p>
            Informamos que existen servicios que se ofrecen a través de Internet
            estos pueden utilizar mecanismos como son Cookies, Web Beacons y
            otras tecnologías a través de las cuales se recaban datos de manera
            automática y simultánea, como la dirección IP de origen, navegador
            utilizado, sistema operativo, momento en que se accedió a la página,
            siendo posible monitorear su comportamiento como usuario de los
            servicios de Internet. A continuación, definimos cada término:
          </p>
          <p>
            <span>Cookies:</span> Archivo de datos que se almacena en el disco
            duro del equipo de cómputo o del dispositivo de comunicaciones
            electrónicas de un usuario al navegar en un sitio de internet
            específico, el cual permite intercambiar información de estado entre
            dicho sitio y el navegador del usuario. La información de estado
            puede revelar medios de identificación de sesión, autenticación o
            preferencias del usuario, así como cualquier dato almacenado por el
            navegador respecto al sitio de internet.{" "}
          </p>
          <p>
            <span> Web beacons:</span> Imagen visible u oculta insertada dentro
            de un sitio web o correo electrónico, que se utiliza para monitorear
            el comportamiento del usuario en estos medios. A través de éstos se
            puede obtener información como la dirección IP de origen, navegador
            utilizado, sistema operativo, momento en que se accedió a la página,
            y en el caso del correo electrónico, la asociación de los datos
          </p>
          <p>
            Por lo anterior, se informa que en todo momento se pueden
            deshabilitar el uso de estos mecanismos, de acuerdo a las
            instrucciones que cada empresa propietaria de los browsers
            (navegador o visor de Internet) tiene implementado para activar y
            desactivar las citadas Cookies y Web Beacons.
          </p>
          <p>
          <span> Controles del navegador: </span>La mayoría de los navegadores
          le permitirán acceder a las cookies que se han almacenado en su
          equipo, y podrá eliminarlas de forma individual, o bloquearlas para
          todos los sitios web o algunos en especial. Cualquier preferencia que
          haya establecido se perderá si elimina todas las cookies, incluyendo
          las opciones para optar por no utilizarlas, ya que este proceso
          requiere inhabilite el uso de las mismas.
          </p>
          <p>
            Para obtener más información acerca de cómo modificar la
            configuración del navegador para bloquear o filtrar cookies, puede
            consultar{" "}
            <Link style={{ color: "blue" }} to="http://www.aboutcookies.org/">
              http://www.aboutcookies.org/
            </Link>{" "}
            (Inglés) o
            <Link
              style={{ color: "blue" }}
              to="http://www.cookiecentral.com/faq/ "
            >
              {" "}
              http://www.cookiecentral.com/faq/{" "}
            </Link>
            (Inglés).
          </p>
          <h2>IX. MODIFICACIONES AL AVISO DE PRIVACIDAD</h2>
          <p>
            Este aviso de privacidad podrá ser modificado de tiempo en tiempo
            por Lease for U dichas modificaciones serán oportunamente informadas
            a través de nuestra página en internet <Link style={{ color: "blue" }} to="www.leaseforu.com"> www.leaseforu.com</Link>, o por
            cualquier otro medio de comunicación oral, impreso o electrónico que
            Lease for U determine para tal efecto y que le hará de su
            conocimiento.
          </p>
        </div>
      </div>
    </AvisoStyle>
  )
}

export const Head = () => <Seo title="Aviso de Privacidad" />

export default AvisoPrivacidadPage
